import React from 'react'
import PropTypes from 'prop-types'
import { ScanSettings, Barcode } from 'scandit-sdk'
import ScanditBarcodeScanner from 'scandit-sdk-react'

import { BarcodeScannerContainer } from './styled'
import config from 'src/common/config'

const BarcodeScanner = props => {
  const { onScan } = props
  return (
    <BarcodeScannerContainer>
      <ScanditBarcodeScanner
        licenseKey={config.SCANDIT_LICENSE_KEY}
        engineLocation={'https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build'}
        scanSettings={
          new ScanSettings({
            enabledSymbologies: [
              Barcode.Symbology.CODE128,
              Barcode.Symbology.EAN13,
              Barcode.Symbology.EAN8
            ],
            codeDuplicateFilter: -1
          })
        }
        enableCameraSwitcher={false}
        onScan={scanResult => onScan(scanResult.barcodes[0].data)}
        onScanError={console.error}
        onError={console.error}
      />
    </BarcodeScannerContainer>
  )
}
BarcodeScanner.propTypes = {
  onScan: PropTypes.func
}
export default BarcodeScanner
