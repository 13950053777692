import axios from 'axios'
import config from './config'

export const BE_API = axios.create({
  baseURL: config.API_URL,
  headers: {
    'API-Key': 'C1E149AB-7F6C-4915-BDE4-EB3B44BADD47'
  }
})

const apis = {
  user: {
    authenticate: token =>
      BE_API.get('/user', { headers: { Authorization: `Bearer ${token}` } })
  },
  store: {
    list: () => BE_API.get('/stores')
  },
  scan: {
    details: (code, storeId) => BE_API.get('/scan', { params: { code, storeId } })
  }
}
export default apis
