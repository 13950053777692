const env = process.env.REACT_APP_ENV
const redirectAfterLogin = encodeURIComponent(window.location.href)

const envConfig = {
  dev: {
    LOGIN_URL: `https://test-lmit-checkrotture-nprd-ping-qqbkip3qza-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'http://localhost:8080',
    SCANDIT_LICENSE_KEY:
      'AWIwiD54HUXvQf/Iwxtn6jQ3xOkdG0g9WQnsEYpaVdsPFbqsYU5QF1RL09CMTMcPpGNdWXdhWYGVMxlMmBGyZFttoJZ2IQQPWUewybd9VLr0XHraxk+qNvVxMDLCdSSmRxZXiJsty5JyJuKGUSjxaJaMLEfdmUyLiDyB8vtxDQRFuWJx/+sLfeF8I2wB/C4irroGsFJ4umM9v0PJ3u4irKqeKEcvhOzoGBaBlzvD7YkjwQj+s4fP3Akgt76wg2vB3YKlKdPMkAznyJHE1uBO7bi9L5OlaNlPjpkoP2iGTSVBmr1+Jo+vT9V4qVEZbVGIt8W7vAtWpKuSgRWneMJ48xKF8H22njephWmi29HNI7sijgo6sjrU++GN2SUVzK0YofyIQlkWZIep65f+NIxqh+n+PS0WDhQS/SrO1nVf7rFuGdKkInrGZQ36ph4jnED3mliR+zJG5yUWHMRKeczPamXaKB3NgYyIH3pyVFEi4h0iie0qAzTdhcaETROWOduhkKPCmQbsNnzUc0kZEvqr7T5uzbLKByEq3DHLKWlX/EhB5RkxGZXii9PjdwJaEiXRezlaVtmAh2je5B6CF02Xd+sAenpyQu0nfGk7HDxy7Nz/zSdb+ChbG0XFpa+uTz4nGoMyFProjIQzsdESB8vA1EQ/xbW572KO0Q1MR4dovWFs5+tI9c6abzcerYwi78/HBg30K6IVGfAXNBWYQGlHbOdKX3DxGG3HxHzC24vVl62+HBotqQ8eampF0O//qZVcdBUP8mFOFSAH4hYVPa4mT6Xmq8a3FKsIh1AKSKwdFT6TTAgWZDoyGo09OKtV0cE=' //ngrok
  },
  test: {
    LOGIN_URL: `https://test-lmit-checkrotture-nprd-ping-qqbkip3qza-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://test-checkrotture-api-qqbkip3qza-ew.a.run.app',
    SCANDIT_LICENSE_KEY: 'ARlgVyq3CaRqPjr3RSRxDedD1xRQJJWg1EwRM2F5TlZPLao682kiYetqrO+OW6KFf1SNv51y1+lXfs3vh2IcFdQNYy0qeErRBGd1zA1F3H+iAePCs3esgmRCaKwbc4GH/kkkxdYlWfbeXXsNMV70p9B+e0olURNM6WkNQH1421JOelbaPVMXghRBPUKAEzCB8WU64t1MxDw3e/ey8j5WIWI8QisoCciZ4gthyjwqzPFpP4HzqwaKzzyAP9W349GHXTS9rd8pAGnhZ8Gyao73HVSMhlXKJ5KXCHxKEsxnur0Js0jFmQ8bZRVJM18VH6gjpYPzxYaN4thBaTK5/eNrn/C7yF+XAT0PlddLMeiuLGYKLPYKFNlLg8We+vM67jEC6vO3idpUPwccXjdvIdNfOGOk6ZHy2oQatwM2GINPRBK3t1J2ZwnHVf5+8Joh0wWNhGCka1qjZHl09pWWXilo6j5dxwIO6iXj8lz0QMas1Z6XqvSDW+9js1Wl/HcaUH/2vHHa5UB9+3pMrBpd+fGZglSVtbQRd1GoTzX0l5JS9mYgZyjJKhsAOAIHyCc6Qf0d3H/Yd3co166RcSx8XjeNY/s2wuucOHT19ArbZGYUEMzZiBmxQocB2nDNV46BAxx/l2TZdEftjw+f89TSoE2MEsM2bT9xGTJ//7/omuwMPN8nuBOftuGx8OK1ZgSjrkx6SWBpJayejsas8B+qF7tdYU/N62RTxyET79rswGYyEASKKPvFzsucrn0Jwn4UmMnK6CgZhywQdbpowwMJHt1sdq79tzOkp3RFe2J0tGCrINMyGuUOAXWCf3nsykgck0OS9byrb2LrLKOo9p8jESAXZqY7dLcxsVAVggEv8REq4HwohQvhpnWQzlPEjCM5lN9ycVmgvheLKsWYVPvHrw0=' // https://checkrotture-test.cloud.leroymerlin.it/
  },
  prod: {
    LOGIN_URL: `https://prod-lmit-checkrotture-prd-ping-6qxyhex7dq-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://prod-checkrotture-api-6qxyhex7dq-ew.a.run.app',
    SCANDIT_LICENSE_KEY: 'ATZwboWBETn2O0a35AbQP1Yy9nqUKCEwqGgtSUJa7exKPX8BhFvF47Boh68gd1CkzW5MZ396eWSdQ50Ymj3OLuZIyu1DdqONPiq/7YRLdVyIf8PgzmPHcGAKsCLdahbP2WEAWfNwc784cSsPl2aOnGZEbahHKeF65kdG3i9nnS/LR1J7unREFSV6WX79SD8AcmrYK1sZG/e7ACub3kNNg54q/oZLxMyUthnekg8wqnbeYAIOKLkc2LxEIzcDLXTHC6/Qq7I+8UxSilUguYKsxQTguncplO/6E6BCUYZkUm3XFwZfO2axhXreD8p8ZgcjAcV2ak5oiyEXZKa5LfZcFpxfUGQmMuHAXu+TVldI7n1udM3EQJTAmyAA3TGxZXKXSPHM9v8y8T6IhP1HF8jIllQd10PHjkgmUmnM4OZdt5A+2a+tktHi3ZeVi+b3g9A/CsBE0+0A/mOOUgvIcWkqdySCWe2ETrRzP64NCkyIW35DNLMQFJLGiBDIWjs5M40q+YLlZbqquqk/3e96zRKCiv3MGeh2tLvwjiybW2bCGiTmIrn8bZM10OIRr0EQS1hyXnRAocZqjpmJ4K8nR8HQ/Hd37k5u6btC2rmpwZP9yDHAaM4lcKkhyXyQ+aPjiGKdisTu2FUwxpqKTxP8oC3XRNgt0xXcRZ196RcqHjUyDtONnQwFEtz5zDDCoEEt7F/LmUpBI18sWx64p31C97HYrPux0+7x/J0aOm8lqQeZpYxZnHeT8bEFvdlJvr+Pa1EvFTgXvEgfSXIpsTRQL0Tx9+uLYEDvuRYbcQ9+yJ71mxxKvLX8suaytuUZcmNS/h9Zxerj08gXDkBu9NIbXbpkNRD6T+6V3GJA8b8foHgahLSDvT7ewGCTg1Q/kK3EQqKeZn0Cig==' // https://checkrotture.cloud.leroymerlin.it/
  }
}

export default envConfig[env]
